<template>
    <div class="flex justify-center mb-3">
        <div v-if="!activeTask" @click="() => (this.startTaskModal = true)" class="container__timer">
            <img alt="play" src="@/assets/ico_play.svg" />
            <stopwatch />
        </div>
        <div v-if="activeTask" @click="openEndTaskModal" class="container__timer">
            <img alt="stop" src="@/assets/stop_timer.svg" />
            <stopwatch :start="activeTask.created_at" />
        </div>
        <el-dialog :close-on-click-modal="false" title="Start Task" :visible.sync="startTaskModal" width="50%"
            @close="() => (this.startTaskModal = false)">
            <div ref="startTaskModalBody" class="dialog__body">
                <RpmDashboardErrors v-bind:errors="startTaskErrors" v-bind:title="'Start Task Errors'" />
                <div class="grid gap-4">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_task">
                            Select Task Type
                        </label>
                        <select id="select_task" v-model="selectedTaskType"
                            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option v-for="taskType in taskTypes" v-bind:value="taskType" v-bind:key="taskType">
                                {{ taskType }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="notes">
                            Description <span class="required">*</span>
                        </label>
                        <input v-model="taskDescription"
                            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="notes" placeholder="" />
                    </div>
                </div>
            </div>
            <span slot="footer" class="josefin dialog-footer">
                <el-button @click="createTask" type="primary" :disabled="loading">Start Task</el-button>
                <el-button @click="() => (this.startTaskModal = false)" type="cancel">Cancel</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="End Task" :visible.sync="endTaskModal" width="50%" @close="() => (this.endTaskModal = false)">
            <div v-if="!activeTask">
                No active task...
            </div>
            <div v-if="activeTask" ref="endTaskModalBody" class="dialog__body">
                <RpmDashboardErrors v-bind:errors="endTaskErrors" v-bind:title="'End Task Errors'" />
                <div class="grid gap-4">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_template">
                            Select Template
                        </label>
                      <el-select id="select_template" @change="templateChange" v-model="selectedTemplate" class="w-full" clearable filterable>
                        <el-option v-if="activeTask.category === 'ccm'" v-for="template in ccmTaskTemplates" :value="template" :key="template.id" :label="template.name"/>
                        <el-option v-if="activeTask.category === 'rpm'" v-for="template in rpmTaskTemplates" :value="template" :key="template.id" :label="template.name"/>
                      </el-select>
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="notes">
                            Note <span class="required">*</span>
                        </label>
                        <textarea required rows="4" ref="taskNotesRef" @keydown.tab.prevent="tabTemplate('taskNotesRef')" v-model="notes"
                            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="notes" placeholder="" />
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="notes">
                            Duration in minutes <span class="required">*</span>
                        </label>
                        <input v-model="taskDuration" type="number" interval="1" min="0"
                            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="notes" :placeholder="taskDurationPlaceholder" />
                    </div>
                </div>
            </div>
            <span slot="footer" class="josefin dialog-footer">
                <el-button @click="closeTask" type="primary" :disabled="loading">End Task</el-button>
                <el-button @click="() => (this.endTaskModal = false)" type="cancel">Cancel</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import RpmDashboardErrors from "../../../../components/RpmDashboardErrors.vue";
import Stopwatch from "@/components/StopWatch.vue";
import Template from "@/mixins/Template";

export default {
    mixins: [Template],
    components: { RpmDashboardErrors, Stopwatch },

    data() {
        return {
            loading: false,
            activeTask: null,
            startTaskModal: false,
            startTaskErrors: [],
            taskTypes: ["rpm", "ccm"],
            selectedTaskType: "ccm",
            taskDescription: "",
            endTaskModal: false,
            endTaskErrors: [],
            taskDuration: null,
            taskDurationPlaceholder: null,
            accessToken: null,
        };
    },
    computed: {
        ...mapState(["patients"]),
        templates: function () {
            return this.patients.templates;
        },
        rpmTaskTemplates: function () {
          return this.templates.filter(t => t.category === 'rpm_task');
        },
        ccmTaskTemplates: function () {
          return this.templates.filter(t => t.category === 'ccm_task');
        },
    },
    methods: {
        ...mapActions(["getTemplates"]),
        async getAccessToken() {
          this.accessToken = await this.$auth.getTokenSilently();
        },
        openEndTaskModal() {
            this.taskDuration = null;
            this.taskDurationPlaceholder = moment.utc().diff(moment.utc(this.activeTask.created_at), "minutes");
            this.endTaskModal = true;
        },
        createTask() {
            if (!this.checkCreateTaskForm()) {
                this.$nextTick(() => {
                    this.$refs.startTaskModalBody.scrollIntoView();
                });
                return;
            }

            this.loading = true;

            Vue.$http.post(`/api/rpm_ccm_dashboard/create_task`, null, {params: {type: this.selectedTaskType, description: this.taskDescription, patient_id: this.patient.id, token: this.accessToken, status: 'in_progress'},})
                .then((res) => {
                    if (!res.error) {
                        this.activeTask = res.data.data;
                        this.startTaskModal = false;
                        this.openEndTaskModal();
                        this.$emit('get-patient-details');
                    }
                })
                .catch(error => this.handleEhrApiErrors(error, this.startTaskErrors, this.$refs.startTaskModalBody)).finally(() => {
                    this.loading = false;
                });
        },
        checkCreateTaskForm() {
            const required = ["Description"];
            this.startTaskErrors = this.checkForm(required, {
                Description: this.taskDescription,
            });
            return !this.startTaskErrors.length;
        },
        closeTask() {
            if (!this.checkCloseTaskForm()) {
                this.$nextTick(() => this.$refs.endTaskModalBody.scrollIntoView());
                return;
            }
            this.activeTask.status = "closed";
            this.activeTask.note = this.notes;
            this.activeTask.duration = this.taskDuration;

            this.loading = true;

            Vue.$http.post(`/api/rpm_ccm_dashboard/update_task`, this.activeTask, { params: { token: this.accessToken } })
                .then(async () => {
                    this.$awn.success("Task Saved");
                    await this.$emit('get-patient-details');
                    this.endTaskModal = false;
                })
                .catch((error) => this.handleEhrApiErrors(error, this.endTaskErrors, this.$refs.endTaskModalBody)).finally(() => {
                    this.loading = false;
                });
        },
        checkCloseTaskForm() {
            const required = ["Note", "Duration"];
            this.endTaskErrors = this.checkForm(required, {
                Note: this.notes,
                Duration: this.taskDuration,
            });
            return !this.endTaskErrors.length;
        },
        checkForm(required, formValuesObj) {
            return required.filter(key => !formValuesObj[key]).map(key => key.replace("_", " ") + " is required.");
        },
        checkActiveTask() {
            if (this.patient.tasks && this.patient.tasks.find(t => t.status === "in_progress")) {
                this.activeTask = this.patient.tasks.find(t => t.status === "in_progress");
                this.notes = this.activeTask.note;
            } else {
                this.activeTask = null;
                this.notes = "";
            }
        }
    },
    created() {
        this.getAccessToken().then(this.checkActiveTask());
    },
  watch: {
        patient() {
            this.checkActiveTask();
        },
    },
};
</script>

<style scoped>
.container__timer {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px dashed #d4d4d4;
    border-radius: 50px;
    padding: 11px 16px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    cursor: pointer;
}
</style>
